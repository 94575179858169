<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Based on the titration curve shown below, determine which of the indicators listed below
        would be appropriate for determining the titration end-point. A list of the indicators can
        be found
        <a
          href="https://openstax.org/books/chemistry-2e/pages/14-7-acid-base-titrations#CNX_Chem_14_07_indicators"
          target="_blank"
          rel="noopener noreferrer"
        >
          here</a
        >.
      </p>

      <p class="pl-12 mb-6">
        <v-img style="max-width: 500px" :src="imageName" />
      </p>

      <div v-for="option in options1" :key="option.value" class="mb-2">
        <v-checkbox
          v-model="inputs.indicators"
          class="d-inline-block my-0 py-1"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question421',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        indicators: [],
      },
      options1: [
        {expression: '$\\text{Methyl orange}$', value: 'methylOrange'}, // 3.2 - 4.5
        {expression: '$\\text{Crystal violet}$', value: 'crystalViolet'}, // 0.0 - 1.8
        {expression: '$\\text{Phenolphthalein}$', value: 'phenolphthalein'}, // 8.0 - 10.0
        {expression: '$\\text{Bromthymol blue}$', value: 'bromthymolBlue'}, // 6.0 - 7.5
        {expression: '$\\text{Thymolphthalein}$', value: 'thymolphthalein'}, // 9.3 - 10.6
        {expression: '$\\text{Methyl red}$', value: 'methylRed'}, // 4.2 - 6.3
      ],
    };
  },
  computed: {
    imageNumber() {
      return this.taskState.getValueBySymbol('imageNumber').content;
    },
    imageName() {
      // 1: 5.7 to 3, methyl orange
      // 2: 7.0 to 3,  methyl orange, (bromthymol not great), methyl red,
      // 3: 8.4 to 11, thymolphthalein, phenolphthalein
      // 4: 6.4 to 11, thymolphthalein, phenolphthalein, maybe bromthymol blue
      return '/img/assignments/titration' + this.imageNumber.value + '.png';
    },
  },
};
</script>
